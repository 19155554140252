import './App.css';
import { NavBar } from './components/globals/NavBar.js';
import { Banner } from './components/main-components/banner/Banner.js';
import { Skills } from './components/main-components/skills/Skills.js';
import { Projects } from './components/main-components/projects/Projects.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import { Experience } from './components/main-components/experience/Experience';
import { AboutMe } from './components/main-components/about-me/AboutMe';
import { Footer } from './components/main-components/footer/Footer';

import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { CarouselPage } from './components/main-components/carousel-page/CarouselPage';
import { StatsBar } from './components/globals/StatsBar';
import { Education } from './components/main-components/education/Education';


function App() {
  const [backgroundImage, setBackgroundImage] = useState('background1');

  const handleScroll = () => {
    const sections = document.querySelectorAll('.section');
    sections.forEach((section, index) => {
      const rect = section.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom > 0) {
        setBackgroundImage(index % 2 === 0 ? 'background1' : 'background2');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Router>
      <div className={`app ${backgroundImage}`}>

        <Routes>
          <Route path="/Project/:id" element={<CarouselPage />} />

          <Route path="/" element={
            <>
              <NavBar />
              <Banner />
              <StatsBar />
              <Experience />
              <Skills />
              <Projects id="projects" />
              <Education/>
              <AboutMe />

              <Footer />
            </>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
