import { Container } from 'react-bootstrap';
import './Education.css';


export const Education = () => {

  return (<section className="exp" id="education">
      <Container>

        <h2>Education</h2>
        <div className="timeline-items">

          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">2021-2023</div>
            <div className="timeline-content">
              <h3>Flutter Developer</h3>
              <p>Around 7 certifications from Udemy, which I can provide upon request.</p>

            </div>

          </div>

          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">Degree date: 2014</div>
            <div className="timeline-content">
              <h3>Physicist</h3>

              <p>Physics degree focused on Materials' Science.</p>
              <p>A 10 semester program that includes advanced mathematics, advanced physics, and programming and numerical methods using C++</p>
              <p>Published researcher: <a href="https://www.researchgate.net/profile/Paulo-Cesar-Plazas-Hurtado"
                                         target="_blank">Scientific Papers
              </a></p>

            </div>

          </div>

        </div>

      </Container>

    </section>

  );

};