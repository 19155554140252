import React from 'react';
import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { introQR, pcpProfPic } from '../../../assets';
import { homeSubTitle, homeTitle } from '../../../constants/strings';
import { HireMeBox } from '../../globals/HireMeBox';
import './Banner.css';


export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const toRotate = ['Front-end Developer', 'UI/UX Designer', '... and Physicist'];
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);
    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1)
      :
      fullText.substring(0, text.length + 1);
    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(400);
    }

  };


  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (

    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">

          <Col id="presentation-column">
            <div>

              <h1>
                {homeTitle} <br />
                {/*<span className="wrap">{text}</span>*/}
              </h1>
              <p>{homeSubTitle.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))
              }</p>

              <img src={introQR} alt="My IntroVideo" style={{ maxWidth: '12%', height: 'auto', cursor:'pointer', }}
                   id="static-image" onClick={handleImageClick}/>
              <p>My Intro</p>

            </div>
          </Col>

          <Col id="hire-me-column">
            <div style={{ height: '10px' }}></div>

            <img src={pcpProfPic} alt="header img" style={{ maxWidth: '30%', height: 'auto' }}
                 id="static-image" />

            <h3>Hi! I'm Paulo</h3>
            {/*<div style={{height: '10px'}}></div>*/}
            <HireMeBox />
            <div style={{ height: '20px' }}></div>
            {/*<img src={coolDash} alt="header img" style={{maxWidth: '45%', height: 'auto'}}/>*/}

          </Col>
        </Row>
        <div className={`modal-overlay ${isModalOpen ? 'show' : ''}`} onClick={closeModal}>
          <div className="modal-content">
            <img src={introQR} alt="Full Size" className="modal-image" />
          </div>
        </div>
      </Container>
    </section>

  );
};
//
//
// <Row className="align-items-center">
//     <Col xs={12} md={6} xl={7}>
//                         <span className="tagline">
//                             Hi! I am Paulo
//                         </span>
//         <h1>{`Welcome here `} <br/>
//             <span className="wrap">{text}</span></h1>
//         <p> Tons of text here</p>
//         <button onClick={() => console.log('connect')}>
//             Let's connect <ArrowRightCircle size={25}/>
//         </button>
//     </Col>
//     <Col s={12} md={6} xl={7}>
//         <img src={coolDash} alt="header img"/>
//     </Col>
//
// </Row>