import { etitc, etitcDesc, freelanceDesc, noldorDesc, startUpDesc } from '../../../constants/strings';
import { Container } from 'react-bootstrap';
import './Experience.css';


export const Experience = () => {

  return (<section className="exp" id="experience">
      <Container>

        <h2>Experience</h2>
        <div className="timeline-items">

          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">2024</div>
            <div className="timeline-content">
              <h3>Front-end Developer</h3>
              <a href="https://dymo.tpeoficial.com/" target="_blank"><h5>TpeOficial</h5></a>
              <p>{startUpDesc}</p>
              <a href="https://www.linkedin.com/company/tpeoficial/about/" target="_blank"><p>Tpe Start-up Linkedin</p></a>
            </div>

          </div>

          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">2023-2024</div>
            <div className="timeline-content">
              <h3>Flutter Developer</h3>
              <h5>Noldor S.A.S. Bogotá</h5>
              <p>{noldorDesc}</p>
            </div>

          </div>
          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">2021-2024</div>
            <div className="timeline-content">
              <h3>Freelancer</h3>
              <p>{freelanceDesc}</p>
            </div>

          </div>
          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">2019-2022</div>
            <div className="timeline-content">
              <h3>Programming Professor</h3>
              <h5>{etitc}</h5>
              <p>{etitcDesc}</p>
            </div>

          </div>

        </div>

      </Container>

    </section>

  );

};