import { useState, useEffect, useRef } from 'react';
import {Nav, Navbar, Container, NavDropdown} from 'react-bootstrap';
import {gmailLogo, githubLogo, linkedin} from '../../assets/icons';


export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);
    const copyEmailToClipboard = () => {
        navigator.clipboard.writeText('paulopdeveloper@gmail.com');
        alert('Email copied to clipboard!');
    };



    useEffect(
        () => {
            const onScroll = () => {
                if (window.scrollY < 50) {
                    setScrolled(true);
                } else {
                    setScrolled(false);
                }

            }
            window.addEventListener('scroll', onScroll);
            return () => window.removeEventListener('scroll', onScroll);
        }, []
    );

    const onUpdateActiveLink = (value)=> {
        setActiveLink(value);
    }

    return (
        <Navbar expand="lg" className={scrolled ? 'scrolled' : ''}>
            <Container>
                <div className="d-flex align-items-center">
                    <Navbar.Brand href="#home" id="my-brand">
                        PauloP-Dev
                    </Navbar.Brand>
                    <div className="social-icon">
                        <a href="#" onClick={copyEmailToClipboard}><img src={gmailLogo} alt='' /></a>
                        <a href="https://github.com/pcplazash" target="_blank"><img src={githubLogo} alt='' /></a>
                        <a href="https://www.linkedin.com/in/paulo-plazas-hurtado/" target="_blank"><img src={linkedin} alt='' /></a>
                    </div>

                </div>

                <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <span className='navbar-toggler-icon'></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">


                    <Nav className="ms-auto">

                        <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'}
                                  onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                        <Nav.Link href="#experience"
                                  className={activeLink === 'experience' ? 'active navbar-link' : 'navbar-link'}
                                  onClick={() => onUpdateActiveLink('experience')}>Experience</Nav.Link>
                        <Nav.Link href="#skills"
                                  className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'}
                                  onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
                        <Nav.Link href="#projects"
                                  className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'}
                                  onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
                        <Nav.Link href="#education"
                                  className={activeLink === 'education' ? 'active navbar-link' : 'navbar-link'}
                                  onClick={() => onUpdateActiveLink('education')}>Education</Nav.Link>
                        <Nav.Link href="#aboutMe"
                                  className={activeLink === 'aboutMe' ? 'active navbar-link' : 'navbar-link'}
                                  onClick={() => onUpdateActiveLink('aboutMe')}>About me</Nav.Link>

                    </Nav>


                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
//
//
// <Navbar expand="lg" className={scrolled ? 'scrolled' : ''}>
//     <Container>
//         <Navbar.Brand href="#home" id="my-brand">
//             PauloP-Dev
//             {/*<img src={linkedplain} alt='Logo'/>*/}
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav">
//             <span className='navbar-toggler-icon'></span>
//         </Navbar.Toggle>
//         <Navbar.Collapse id="basic-navbar-nav">
//             <div className='social-icon'>
//                 <a href='#' onClick={copyEmailToClipboard}><img src={gmailLogo} alt=''/></a>
//                 <a href='#'><img src={githubLogo} alt=''/></a>
//                 <a href='#'><img src={linkedin} alt=''/></a>
//             </div>
//             <div id="sized-box">
//
//             </div>
//             <Nav className="me-auto">
//
//                 <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'}
//                           onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
//                 <Nav.Link href="#experience"
//                           className={activeLink === 'experience' ? 'active navbar-link' : 'navbar-link'}
//                           onClick={() => onUpdateActiveLink('experience')}>Experience</Nav.Link>
//                 <Nav.Link href="#skills"
//                           className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'}
//                           onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
//                 <Nav.Link href="#projects"
//                           className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'}
//                           onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
//                 <Nav.Link href="#aboutMe"
//                           className={activeLink === 'aboutMe' ? 'active navbar-link' : 'navbar-link'}
//                           onClick={() => onUpdateActiveLink('aboutMe')}>About me</Nav.Link>
//
//             </Nav>
//
//             {/*   <span className="navbar-text">*/}
//
//             {/*    <button className='vvd' onClick={() => console.log('connect')}>*/}
//             {/*        <span>*/}
//             {/*            Let's connect*/}
//             {/*        </span>*/}
//
//             {/*    </button>*/}
//             {/*</span>*/}
//         </Navbar.Collapse>
//     </Container>
// </Navbar>

{/*   <span className="navbar-text">*/}

{/*    <button className='vvd' onClick={() => console.log('connect')}>*/}
{/*        <span>*/}
{/*            Let's connect*/}
{/*        </span>*/}

{/*    </button>*/}
{/*</span>*/}