import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
  club1,
  club2,
  club3,
  club4,
  club5,
  club6,
  mobileHor1,
  mobileHor2,
  mobileHor3,
  mobileHor4,
  mobileHor5,
  rolStore1,
  rolStore2,
  rolStore3,
  rolStore4,
  rolStore5, rolStore6, transport1, transport2, transport3, transport4, transport5, transport6,
  webHor1,
  webHor2,
  webHor3,
  webHor4,
  webHor5,
  webHor6,
} from '../../../assets/projects_images';

import {
  finance1,
  finance2,
  finance3,
  firebase1,
  firebase2,
  movies1,
  sip1,
  sip2,
  sip3,
  todo1,
  todo2, todo3, movies2,
  movies3,
} from '../../../assets/projects_images/personal_projects';
import { Container } from 'react-bootstrap';
import './Carousel.css';
import { ProjectsCarousel } from '../../globals/Carousel';
import {
  clubDeRolCorpProjBody,
  financeApp, firebaseApp,
  horizontalDesc,
  moviesApp, rolWebStore,
  sipApp,
  todoApp, transportApp,
} from '../../../constants/strings';


export const CarouselPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const data = {
    1: {
      images: [mobileHor1, mobileHor2, mobileHor3, mobileHor4, mobileHor5],
      title: 'Corporate Business Mobile App',
      text: horizontalDesc,
    },

    2: {
      title: 'Hobby Store App',
      images: [club1, club2, club3, club4, club5, club6],
      text: clubDeRolCorpProjBody,
    },

    3: {
      title: 'Transport Solutions App',
      images: [transport1, transport2, transport3, transport4, transport5, transport6],
      text: transportApp,
    },
    4: {
      title: 'Movies Catalog',
      images: [movies1, movies2, movies3],
      text: moviesApp,
    },
    5: {
      title: 'Finance App',
      images: [finance1, finance2, finance3],
      text: financeApp,
    },
    6: {
      title: 'Systematic Investment Calculator',
      images: [sip1, sip2, sip3],
      text: sipApp,
    },
    7: {
      title: 'TODO Advanced App',
      images: [todo1, todo2, todo3],
      text: todoApp,
    },
    8: {
      title: 'Firebase Mock App',
      images: [firebase1, firebase2],
      text: firebaseApp,
    },
    9: {
      title: 'Corporate Business Web',
      images: [webHor1, webHor2, webHor3, webHor4, webHor5, webHor6],
      text: horizontalDesc,
    },
    10: {
      title: 'Rol Web Store',
      images: [rolStore1, rolStore2, rolStore3, rolStore4, rolStore5, rolStore6],
      text: rolWebStore,
    },
  };

  const carouselData = data[id];

  return (
    <section className="carouselPage">
      <Container>
        <div className="carousel-page-container">

          <ProjectsCarousel
            projectImages={carouselData.images}
            projectTitle={carouselData.title}
            projectText={carouselData.text}

          />
        </div>

        {/*<button onClick={() => navigate('/')}>Back to Projects</button>*/}


      </Container>
    </section>
  );
};
