import { Col, Container, Row } from 'react-bootstrap';


export const StatsBar = () => {

  return (

    <div className="stats-bar">
      <Container className="stats-bar-container">
        <Row className="justify-content-center">

          <Col className="d-flex flex-row align-items-center" >
            <h1>3</h1>
            <p>Years of Experience</p>
          </Col>
          <Col className="d-flex flex-row align-items-center" >
            <h1>3</h1>
            <p>Years as Flutter Dev</p>
          </Col>
          <Col className="d-flex flex-row align-items-center" >
            <h1>1</h1>
            <p>Years as React Dev</p>
          </Col>
          <Col className="d-flex flex-row align-items-center" >
            <h1>2</h1>
            <p>Contributions to Start-ups</p>
          </Col>
          <Col className="d-flex flex-row align-items-center" >
            <h1>+10</h1>
            <p>Corporate and Freelance Projects</p>
          </Col>
        </Row>

      </Container>
    </div>

  );

};