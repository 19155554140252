import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


export const ProjectsCarousel = ({ projectImages, projectText, projectTitle }) => {

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(projectImages[0]);
  const navigate = useNavigate();


  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectedNewImage = (index, projectImages, next = true) => {
    const condition = next
      ? selectedIndex < projectImages.length - 1
      : selectedIndex > 0;
    const nextIndex = next
      ? condition
        ? selectedIndex + 1
        : 0
      : condition
        ? selectedIndex - 1
        : projectImages.length - 1;
    setSelectedImage(projectImages[nextIndex]);
    setSelectedIndex(nextIndex);
  };

  const previous = () => {
    selectedNewImage(selectedIndex, projectImages, false);
  };

  const next = () => {
    selectedNewImage(selectedIndex, projectImages);
  };

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <div className="carousel-container">
      <Row className="flex-md-row flex-column">
        <Col>
          <div className="carousel">
            <LazyLoadImage
              src={selectedImage}
              alt="Project"
              // id="carousel-image"
              className="carousel-image"
              effect="blur"
              onClick={handleImageClick}
            />
            <div className="carousel-controls">
              <button onClick={previous} className="carousel-button">
                ←
              </button>
              <div style={{ width: '30px' }}></div>
              <button onClick={next} className="carousel-button">
                →
              </button>
            </div>
          </div>

        </Col>
        <Col>
          <div className="carousel-text-button-container">
            <div className="carousel-text">
              <h3>{projectTitle}</h3>
              <p>{projectText}</p>
            </div>
            <div style={{ height: '10px' }}></div>
            <button className="carrousel-back-button"
                    onClick={() => navigate(-1)}
            >
              Back to Projects
            </button>
          </div>
        </Col>
      </Row>
      <div className={`modal-overlay ${isModalOpen ? 'show' : ''}`} onClick={closeModal}>
        <div className="modal-content">
          <img src={selectedImage} alt="Full Size" className="modal-image" />
        </div>
      </div>
    </div>
  );
};

//
// {isModalOpen && (
//   <div className="modal-overlay" onClick={closeModal}>
//     <div className="modal-content">
//       <img src={selectedImage} alt="Full Size" className="modal-image" />
//     </div>
//   </div>
// )}
