export const homeTitle = 'Front-end Dev, Flutter Dev, and... Physicist';
export const homeSubTitle = "3 years of experience programming cross-platform apps for Android, iOS, and web using Flutter. \nWeb development focused on React, Next.js and Tailwind CSS. \nProficient in Kotlin and Compose MP. \nEnthusiastic about learning.";

export const experienceTitle = 'Noldor S.A.S.';
export const experienceSubTitle = 'Flutter Developer and UI/UX Designer';


export const physicistCV = 'Curious about my life as scientific researcher and teacher before transitioning to development and design? Take a look at my curriculum as a physicist.';
export const developerCV = 'Experienced as Front-end developer, teacher of programming and numeric methods with certified studies in Flutter, Firebase and UI/UX design.';


export const noldorDesc = `Development of mobile and web applications from scratch using Flutter. Integration with Firebase and various payment platforms, writing user functionalities such as authentication, data management, and 
    secure payment processing.
    Designing user interfaces to subsequently write the Flutter code.`;

export const etitc = 'Instituto Técnico Central - Bogotá D.C.';

export const etitcDesc = 'Teaching programming, and numeric methods as well as fundamental and advanced physics, \n' +
    'statistics, mathematics, and calculus to \n' +
    'university students majoring in Mechanical and Electronic \n' +
    'Engineering.';

export const freelanceDesc= '3 years of coding and designing cross-platform apps, screens and functionalities with Flutter. More than one year of experience as native web developer. Also, working on personal projects to improve my skills as Front-end dev.';
export const startUpDesc='Currently part of the front-end team developing the new website version of the start-up using Next.js and Tailwind CSS. Also contributing to UI/UX design.';


export const teamWorkSkill = 'Collaborated closely with stakeholders, including clients and internal teams, to gather requirements, define project scopes, and deliver tailored solutions that met and exceeded expectations.';

export const corpProjectsBody = 'I significantly contributed to the development of the front-end code and the entire design for both a large and a medium-sized applications. However, due to company policy, I am unable to showcase the repositories. Nonetheless, I can provide designs and additional information.\nI will show you my repos in the next section.';

export const businessCorpTitle = 'Business App';
export const businessCorpProjDescription = 'Developed using Flutter with BLoC as the state manager, this application includes Firebase for user and language management, integrates a payment platform, incorporates a location system, and offers various profiles, including an administrator role. Administrators have the ability to generate and manage payment receipts, handle notifications, access news, participate in chats, and schedule meetings.';

export const horizontalDesc = 'Corporate project. Large size app built for android, iOS and web using Flutter. Its main functionalities are registering users with firebase, handling requests by users to an admin. Admin role allows certain people to create and edit events, tasks, chats, news, bills and more. App is connected to payment platform and manages a large number of users and tokens. Also created all the UI/UX designs using Figma.';
export const clubDeRolCorpProjTitle = 'Hobby Store App';
export const clubDeRolCorpProjBody = 'Corporate project. Medium size app built for android and iOS using Flutter. \nIts main functionalities are registering users with firebase, handling requests by users to an admin. Admin role allows an unique person to create and edit events, news, promos, bills and more. App is connected to payment platform and manages a large number of users and tokens. Also created all the UI/UX designs using Figma.';
export const clubDeRolCorpProjDescription = 'A Flutter mobile application employing BLoC as state manager, Firebase for user management, integrated with payment platform and featuring a notification system.';


export const aboutMeFirst = 'I am deeply passionate about materializing concepts into reality, and I believe that programming offers an exceptional avenue for growth diverse projects of all kinds.';
export const aboutMeSecond = 'After dedicating over a decade to advancing academic knowledge and supporting my university and college students to the best of my abilities, I have made the decision to embark on a new, albeit equally fulfilling, journey in front-end development. I perceive myself as a quick and skilled learner, adaptable, and receptive to novel opportunities.';

export const personalProjectsBody = 'As a part of my experience, I have designed and built the flutter code of some mobile apps.';

export const financeApp = 'Flutter App. Stores and displays information utilizing the Hive package. It incorporates mathematical calculations, graph generation, and statistical analysis. Additionally, it features dark mode, managed by Cubit.';
export const todoApp = 'Flutter App. Utilizes BLoC as state manager and implements Hydrated BLoC for persistence and storage. It also features dark mode, managed by Cubit.';
export const moviesApp = 'Flutter App. Consumes a REST API to display different lists and descriptions of movies. It utilizes Providers as state manager and incorporates search with debouncing.';
export const counterApp = 'Displays animations and custom shapes created with CustomPaint class. It also features dark mode, managed by cubit and a responsive layout.';
export const sipApp = 'Flutter App. Serves as a Systematic Investment Plan calculator, offering automated calculations for investments under the systematic model. It utilizes BLoC as the state manager and dark mode.';
export const firebaseApp = 'Flutter App. Mock app designed for practice, showcasing different types of login methods using firebase.';
export const rolWebStore = 'Freelance work. Under construction: a website designed to manage user accounts, subscriptions, and an online store. Built with Next.js and Tailwind CSS. Also created all the UI/UX designs using Figma.';
export const transportApp = 'Freelance work, still under development. Medium size app built for android, iOS, and web using Flutter. Register users and handle highly customizable delivery requests. Connected to Firebase and Google Maps. Also created all the UI/UX designs using Figma.';
