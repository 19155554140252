import React from 'react';
import { githubLogo, gmailLogo, linkedin } from '../../assets/icons';
import { ArrowRightCircle } from 'react-bootstrap-icons';
// import '../main-components/banner/Banner.css';

export const HireMeBox = () => {
  const copyEmailToClipboard = () => {
    navigator.clipboard.writeText('paulopdeveloper@gmail.com');
    alert('Email copied to clipboard!');
  };

  const openCV = () => {
    window.open('https://firebasestorage.googleapis.com/v0/b/paulopdev-ca183.appspot.com/o/paulop-dev%20cv.pdf?alt=media&token=a0b4e9c9-0a73-49a4-89ac-798f9892edcc', '_blank');
  };

  return (
    <div className="hire-me-box">
      <h2 id="downloadCV-button">Hire Me!</h2>
      <div style={{ height: '5px' }}></div>
      <div className="social-icon">
        <a href="#" onClick={copyEmailToClipboard}><img src={gmailLogo} alt=""
                                                        id="static-image" /></a>
        <a href="https://github.com/pcplazash" target="_blank"><img src={githubLogo} alt="" id="static-image" /></a>
        <a href="https://www.linkedin.com/in/paulo-plazas-hurtado/" target="_blank"><img src={linkedin} alt=""
                                                                                         id="static-image" /></a>
      </div>
      <div style={{ height: '10px' }}></div>
      <p>paulopdeveloper@gmail.com</p>
      <button id="downloadCV-button" style={{ marginTop: '-6px' }} onClick={openCV}>
        Open CV <ArrowRightCircle size={28} />
      </button>

    </div>
  );
};



