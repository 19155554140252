
import "react-multi-carousel/lib/styles.css";
import {Col, Container, Row} from "react-bootstrap";

import {
    flutter,
    React,
    dart,
    javascriptLogo,
    Html,
    CSS,
    cpp,
    git,
    githubLogo,
    gitlab,
    intellijIdea,
    kotlin,
    Compose,
    JavaLogo,
    firebase,
    figma,
    miro,
    mongoDB,
    googlePlayLogo, NextJS, Tailwind,
} from '../../../assets/icons';

import './Skills.css';

export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 4000, min: 3000}, items: 5
        }, desktop: {
            breakpoint: {max: 3000, min: 1024}, items: 3
        }, tablet: {
            breakpoint: {max: 1024, min: 464}, items: 2
        }, mobile: {
            breakpoint: {max: 464, min: 0}, items: 1
        }
    };

    return (<section className="skill" id="skills">
            <Container>

                        <div className="skill-bx">
                            <h2>
                                Skills
                            </h2>
                            <h4>
                                Languages
                            </h4>
                            <p>
                                English: Native<br/>
                                Spanish: Native<br/>

                            </p>
                            <div className="skill-grid">
                                <div className="item" data-title="Flutter">
                                    <img src={flutter} alt="Flutter" />

                                </div>
                                <div className="item" data-title="Dart">
                                    <img src={dart} alt="Dart" />

                                </div>
                                <div className="item" data-title="React">
                                    <img src={React} alt="React" />

                                </div>
                                <div className="item" data-title="Next.js">
                                    <img src={NextJS} alt="Next.js" />

                                </div>

                                <div className="item" data-title="JavaScript">
                                    <img src={javascriptLogo} alt="JavaScript" />

                                </div>
                                <div className="item" data-title="HTML">
                                    <img src={Html} alt="HTML" />

                                </div>
                                <div className="item" data-title="CSS">
                                    <img src={CSS} alt="CSS" />

                                </div>
                                <div className="item" data-title="Tailwind CSS">
                                    <img src={Tailwind} alt="CSS" />

                                </div>
                                <div className="item" data-title="Compose MP">
                                    <img src={Compose} alt="Compose MP" />

                                </div>
                                <div className="item" data-title="Kotlin">
                                    <img src={kotlin} alt="Kotlin" />

                                </div>
                                <div className="item" data-title="C++">
                                    <img src={cpp} alt="C++" />

                                </div>
                                <div className="item" data-title="Java">
                                    <img src={JavaLogo} alt="Java" />

                                </div>
                                <div className="item" data-title="Firebase">
                                    <img src={firebase} alt="Firebase" />

                                </div>
                                <div className="item" data-title="MongoDB">
                                    <img src={mongoDB} alt="MongoDB" />

                                </div>
                                <div className="item" data-title="Git">
                                    <img src={git} alt="Git" />

                                </div>
                                <div className="item" data-title="GitHub">
                                    <img src={githubLogo} alt="GitHub" />

                                </div>
                                <div className="item" data-title="Gitlab">
                                    <img src={gitlab} alt="Gitlab" />

                                </div>
                                <div className="item" data-title="IntelliJ-IDEA">
                                    <img src={intellijIdea} alt="IntelliJ-IDEA" />

                                </div>
                                <div className="item" data-title="Figma">
                                    <img src={figma} alt="Figma" />

                                </div>
                                <div className="item" data-title="Miro">
                                    <img src={miro} alt="Miro" />

                                </div>

                            </div>

                        </div>
            </Container>
        {/*<img className="background-image-landscape" src={secondbg}/>*/}
    </section>);

};

// <Carousel responsive={responsive} infinite={true} className="skill-slider">
//     <div className="item" data-title="">
//         <img src={flutter} alt="Image"/>
//         <h5>Flutter</h5>
//     </div>
//    ...
// </Carousel>