import {Col, Container, Row} from "react-bootstrap";
import {dash1} from "../../../assets/images";
import {githubLogo, gmailLogo, linkedin} from "../../../assets/icons";
import './Footer.css';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import React from 'react';

export const Footer = ()=>{

    const copyEmailToClipboard = () => {
        navigator.clipboard.writeText('paulopdeveloper@gmail.com');
        alert('Email copied to clipboard!');
    };

    const openCV = () => {
        window.open('https://firebasestorage.googleapis.com/v0/b/paulopdev-ca183.appspot.com/o/paulop-dev%20cv.pdf?alt=media&token=a0b4e9c9-0a73-49a4-89ac-798f9892edcc', '_blank');
    };
    // const openPhysicistCV = () => {
    //     window.open('https://firebasestorage.googleapis.com/v0/b/paulopdev-ca183.appspot.com/o/paulop-dev%20cv.pdf?alt=media&token=a0b4e9c9-0a73-49a4-89ac-798f9892edcc', '_blank');
    // };

    return (

        <footer className="footer">
            <Container>
                <Row className="align-items-center">

                    <Col sm={6}>
                        <img src={dash1} alt="Logo" id="footer-dash" />
                        <button id="downloadCV-button" style={{ marginTop: '-6px' }} onClick={openCV}>
                            Open CV <ArrowRightCircle size={28} />
                        </button>
                    </Col>
                    <Col sm={6} className="footer-second-column">
                        <div className="social-icon">
                        <a href="https://github.com/pcplazash" target="_blank">
                                <img src={githubLogo} />
                            </a>
                            <a href="https://www.linkedin.com/in/paulo-plazas-hurtado/" target="_blank">
                                <img src={linkedin} />
                            </a>
                            <a href="#" onClick={copyEmailToClipboard}>
                                <img src={gmailLogo} />
                            </a>
                        </div>
                        <h4>Paulo Plazas</h4>
                        <p>Front-end Dev</p>

                    </Col>
                </Row>


            </Container>
        </footer>


    );

}
//paulopdeveloper@gmail.compaulopdeveloper@gmail.com
// paulopdeveloper@gmail.compaulopdeveloper@gmail.com
// designerusingeadership