import './AboutMe.css';
import { Col, Container, Row } from 'react-bootstrap';
import { aboutMeFirst, aboutMeSecond } from '../../../constants/strings';


export const AboutMe = () => {

  const skills = [
    'Teamwork', 'Communication', 'Problem Solving', 'Adaptability', 'Focusing',
    'Learning', 'Creativity', 'Empathy', 'Critical Thinking', 'Leadership', 'Patience',
  ];

  const hobbies = [
    'Bass Guitar', 'Kung Fu', 'Tennis', 'Reading', 'Cooking', 'Languages', 'Archaeology', 'Videogames',
  ];

  return (
    <section className="aboutMe" id="aboutMe">
      <Container>

        <div className="aboutMe-bx">
          <h2>About Me</h2>
          <Row className="aboutMe-row">
            <Col className="aboutMe-col-left">
              <div className="text-box text-box-left">
                <p>{aboutMeFirst}</p>
              </div>
              <div className="skills-section">
                <h3>Soft Skills</h3>
                <div className="soft-skills-grid">
                  {skills.map(skill => (
                    <div key={skill} className="soft-skill-bx">
                      {skill}
                    </div>
                  ))}
                </div>

              </div>
            </Col>
            <Col className="aboutMe-col-right">
              <div className="text-box text-box-right">
                <p>{aboutMeSecond}</p>
              </div>
              <div className="skills-section">
                <h3>Hobbies</h3>
                <div className="soft-skills-grid">
                  {hobbies.map(skill => (
                    <div key={skill} className="soft-skill-bx">
                      {skill}
                    </div>
                  ))}
                </div>
              </div>

            </Col>
          </Row>

        </div>

      </Container>
    </section>

  );

};

/*

          <Row>
            <div className="text-box text-box-left">
              <p>{aboutMeFirst}</p>
            </div>
            <div className="text-box text-box-right">
              <p>{aboutMeSecond}</p>
            </div>
          </Row>
          <div className="skills-section">
            <h3>Soft Skills</h3>
            <div className="soft-skills-grid">
              {skills.map(skill => (
                <div key={skill} className="soft-skill-bx">
                  {skill}
                </div>
              ))}
            </div>
            <h3>Hobbies</h3>
            <div className="soft-skills-grid">
              {hobbies.map(skill => (
                <div key={skill} className="soft-skill-bx">
                  {skill}
                </div>
              ))}
            </div>

          </div>


* */